import { useGridApiRef } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import React, {
  createContext,
  useContext,
  ReactNode,
  MutableRefObject,
  useEffect,
  useState,
  useCallback,
} from 'react';

const PageDataContext = createContext<PageDataContextType>(
  {} as PageDataContextType
);

export interface PageDataProviderProps {
  children: ReactNode;
  grids: Record<string, GridMetaData>;
}

export interface GridMetaData {
  name: string;
  ref: MutableRefObject<GridApiPremium>;
  isReady: boolean;
  checkIsReady: () => void;
}

export interface PageDataContextType {
  grids: Record<string, GridMetaData>;
}

/**
 * Provide a named MUI datagrid API ref, with a loading state flag.
 *
 * Initialize this at the page level and consume it from any component that needs to read/write to a data grid.
 *
 * For multiple grids, call this once for each grid and pass into a <PageDataProvider />
 */
export function useGridMetaData(name: string) {
  const ref = useGridApiRef();
  const [isReady, setIsReady] = useState<boolean>(false);

  const checkIsReady = useCallback(() => {
    setIsReady(!!ref?.current?.state?.rows);
  }, [ref]);

  useEffect(() => {
    checkIsReady();
  }, [ref, checkIsReady]);

  return { ref, isReady, name, checkIsReady };
}

/**
 * Currently exposes an object of named data grid references. Will be augmented in the future to contain reusable page-level data grid logic (and will be renamed appropriately).
 */
export function PageDataProvider({ children, grids }: PageDataProviderProps) {
  return (
    <PageDataContext.Provider value={{ grids }}>
      {children}
    </PageDataContext.Provider>
  );
}

export const usePageDataContext = () => {
  return useContext(PageDataContext);
};

export type WithDataGridApiRef<T> = T & {
  apiRef: React.MutableRefObject<GridApiPremium>;
};
