/**
 * Tell us how long a function takes to run. Output to the console.
 *
 * @param label - The label to use for the console output.
 * @param fn - The function to profile.
 * @returns The return value of the function.
 */
export function profile<T = void>(label: string, fn: () => T): T {
  const then = performance.now();

  const value = fn();

  const now = performance.now();

  console.log(`VDX Profiler: ${label} took ${(now - then) / 1000}s`);

  return value;
}
