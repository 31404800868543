import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'setupAxios.ts';
import { store } from './redux/store';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { theme } from './utils/theme';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import AppAuth0Provider from './AppAuth0Provider';
import { loadLocale } from './utils/date';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ScrollToTop from 'components/common/Widgets/ScrollToTop';
import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  debug: process.env.NODE_ENV === 'development',
};

export function AppProvider({ children }) {
  // get the first part of the browser language setting, e.g., 'en' from 'en-US'
  useEffect(() => {
    loadLocale(navigator.language.split('-')[0]);
  }, []);

  // set the text for the apply button in date pickers to "Apply" rather than "OK" only if english is the language
  const customLocaleText = navigator.language.startsWith('en')
    ? {
        okButtonLabel: 'Apply',
      }
    : {};

  return (
    <Provider store={store}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <BrowserRouter>
          <AppAuth0Provider>
            <ScrollToTop />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  adapterLocale={navigator.language.split('-')[0]}
                  dateAdapter={AdapterDayjs}
                  localeText={customLocaleText}
                >
                  {children}
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </AppAuth0Provider>
        </BrowserRouter>
      </PostHogProvider>
    </Provider>
  );
}
