import React, { Fragment, SyntheticEvent } from 'react';
import {
  Autocomplete,
  Chip,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { MuiIconManifest } from 'utils/iconManifest';

export interface CsvUploadModalFieldInfo {
  fieldName: string;
  fieldLabel: string;
  fieldDescription: string;
}
interface UploadModalProps {
  label: string;
  file: File | null;
  columns: string[];
  setSelectedColumn?: ({
    fieldConfig,
    value,
  }: {
    fieldConfig: CsvUploadModalFieldInfo;
    value: string;
  }) => void;
  csvError: string;
  mainLabel: string;
  fields: CsvUploadModalFieldInfo[];
  separator?: string;
}

interface CsvUploadModalRowProps {
  fieldConfig: CsvUploadModalFieldInfo;
  columns: string[];
  onChange: (
    e: SyntheticEvent,
    fieldConfig: CsvUploadModalFieldInfo,
    value: string | null
  ) => void;
  csvError: string;
}

export interface CsvUploadModalFieldConfig
  extends Record<string, CsvUploadModalFieldInfo[]> {}

export const fieldConfigurations: CsvUploadModalFieldConfig = {
  organizations: [
    {
      fieldName: 'domain',
      fieldLabel: 'Domain',
      fieldDescription: 'Column containing domain',
    },
  ],
  people: [
    {
      fieldName: 'email',
      fieldLabel: 'Email',
      fieldDescription: 'Column containing email',
    },
  ],
  'job-titles-keywords': [
    {
      fieldName: 'job_title',
      fieldLabel: 'Job titles',
      fieldDescription: `Column containing job titles`,
    },
  ],
};

/**
 * Add UUID fields only for Vendelux employees.
 */
export const vendeluxEmployeeFieldConfigurations: CsvUploadModalFieldConfig = {
  ...fieldConfigurations,
  organizations: [
    ...fieldConfigurations.organizations,
    {
      fieldName: 'uuid',
      fieldLabel: 'UUID',
      fieldDescription: 'Column containing UUIDs',
    },
  ],
  people: [
    ...fieldConfigurations.people,
    {
      fieldName: 'uuid',
      fieldLabel: 'UUID',
      fieldDescription: 'Column containing UUIDs',
    },
  ],
};

export function CsvUploadModalRow({
  fieldConfig,
  columns,
  onChange,
  csvError,
}: CsvUploadModalRowProps) {
  const { fieldName, fieldLabel } = fieldConfig;

  return (
    <Grid container direction="row" gap={2} alignItems="center">
      <Grid item>
        <Chip label={fieldLabel} sx={{ ml: 0 }} />
        <MuiIconManifest.EastIcon fontSize="small" />
      </Grid>
      <Grid item flexGrow={1}>
        <Autocomplete
          readOnly={csvError !== ''}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Select column header"
              name={fieldName}
            />
          )}
          onChange={(e, value) => {
            onChange(e, fieldConfig, value);
          }}
          options={columns}
        />
      </Grid>
    </Grid>
  );
}

export function CsvUploadModal({
  file,
  columns,
  setSelectedColumn,
  csvError,
  fields,
  separator = 'AND',
}: UploadModalProps) {
  const theme = useTheme();

  return (
    <Stack spacing={4}>
      <Typography>
        Selected file:{' '}
        <Typography component="span" color={theme.palette.primary.main}>
          {file?.name}
        </Typography>
      </Typography>
      <Stack spacing={2}>
        <Grid container direction="row" gap={2} alignItems="center">
          <Typography fontWeight="bold">
            Match the columns in your file to the fields below
          </Typography>
        </Grid>
        {fields.map((field, index) => (
          <Fragment key={index}>
            <CsvUploadModalRow
              fieldConfig={field}
              columns={columns}
              csvError={csvError}
              onChange={(_e, fieldConfig, value) => {
                if (setSelectedColumn) {
                  if (value)
                    setSelectedColumn({
                      fieldConfig,
                      value,
                    });
                  else setSelectedColumn({ fieldConfig, value: '' });
                }
              }}
            />

            {!!separator && index < fields.length - 1 ? (
              <Divider textAlign="left">{separator}</Divider>
            ) : null}
          </Fragment>
        ))}
      </Stack>
      {csvError && (
        <Typography color={theme.palette.error.main}>{csvError}</Typography>
      )}
    </Stack>
  );
}
